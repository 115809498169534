import { render, staticRenderFns } from "./YBackgroundStar.component.vue?vue&type=template&id=45e74bf2&scoped=true&"
import script from "./YBackgroundStar.component.ts?vue&type=script&lang=ts&"
export * from "./YBackgroundStar.component.ts?vue&type=script&lang=ts&"
import style0 from "./YBackgroundStar.component.scss?vue&type=style&index=0&id=45e74bf2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e74bf2",
  null
  
)

export default component.exports