




































import { Component, Vue } from 'vue-property-decorator';
import YBackground from './components/background/YBackground/YBackground.component.vue';
import YToolbar from './components/YToolbar/YToolbar.component.vue';
import YLanguageSelector from './components/YLanguageSelector/YLanguageSelector.component.vue';
import LanguageManager from './scripts/LanguageManager';
import NavigationUtils from './router/nagivation-utils';
import YMobileToolbar from '@/components/mobile-components/YMobileToolbar/YMobileToolbar.component.vue';
import YNavigationDrawer from '@/components/mobile-components/YNavigationDrawer/YNavigationDrawer.component.vue';
import YConceptionDetails from '@/components/YConceptionDetails/YConceptionDetails.component.vue';
import YImageCarousel from '@/components/articles/YImageCarousel/YImageCarousel.component.vue';


@Component({
  components: {
    YToolbar,
    YBackground,
    YLanguageSelector,
    YMobileToolbar,
    YNavigationDrawer,
    YConceptionDetails,
    YImageCarousel
  },
})
export default class App extends Vue {
  sheetScrollingClass = "";
  timeout : any;

  mounted() : void {
    setTimeout(() => {
      this.initLanguage();
    }, 10);
    window.addEventListener('scroll', () =>{
      this.sheetScrollingClass = "scrolling";
      //const sheet = (this.$refs.sheet as any).$el as HTMLElement
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.sheetScrollingClass = "";
      }, 800)
    }, true);
  }

  /**
   * @desc loads language data, considering available informations from localStorage and navigator
   */
  private initLanguage() : void {
    const language = localStorage.getItem("lang");
    // if there's a language in localStorage
    if(language && language.length) {
      LanguageManager.loadLanguage(language);
      this.$root.$emit("language-update");
    }
    else {
      const navigatorLanguage = NavigationUtils.getNavigatorLanguage();
      // loads navigator language if it's available, else, loads french data
      const defaultLanguage = navigatorLanguage.length ? navigatorLanguage : 'fr';
      LanguageManager.loadLanguage(defaultLanguage);
      this.$root.$emit("language-update");
    }
  }
}

